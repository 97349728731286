import React, { useState, useEffect } from "react";
import {
  ContentContainer,
  HomeContent,
  Title,
  Image,
  Seo,
} from "../components";
// import { Posts } from "../templates/allPosts"
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Link, animateScroll as scroll } from "react-scroll";
import { Fade, Slide } from "react-awesome-reveal";
import { BsArrowRightShort } from "react-icons/bs";

// import Container from "react-bootstrap/container"
// import Row from "react-bootstrap/Row"
// import Col from "react-bootstrap/Col"
// import Navbar from "react-bootstrap/Navbar"
import { FaGithub, FaTwitter, FaLinkedin, FaChevronDown } from "react-icons/fa";

// import "bootstrap/dist/css/bootstrap.min.css"
import "../css/index.css";

const IndexPage = props => {
  const { data } = props;
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const profileImage = data.profileImage.childImageSharp.fluid;
  const mountainImage = data.mountainImage.childImageSharp.fluid;
  const skyImage = data.skyImage.childImageSharp.fluid;
  const hillImage = data.hillImage.childImageSharp.fluid;
  const projectMedianXL = data.projectMedianXL.childImageSharp.fluid;
  const projectPomodoro = data.projectPomodoro.childImageSharp.fluid;
  const projectPokedex = data.projectPokedex.childImageSharp.fluid;
  const projectSentosa = data.projectSentosa.childImageSharp.fluid;

  return (
    <ContentContainer>
      <Seo title="Home" />
      <HomeContent>
        <section id="intro-section" className="homepage-sections">
          <div className="parallax-wrapper">
            {/* <div className="parallax-container">
              <Img fluid={skyImage} alt="Profile Image" />
            </div>
            <div className="parallax-container">
              <Img fluid={mountainImage} alt="Profile Image" />
            </div> */}
            {/* <div
              className="parallax-container"
              style={{ transform: `translateX(-${offsetY * 0.25}px)` }}
            >
              <Img fluid={hillImage} alt="Profile Image" />
              <img src="../images/Sky.png" alt="" />
            </div> */}

            <div className="parallax-container">
              {/* <Img fluid={hillImage} alt="Profile Image" /> */}
              <img src="../images/Mountains.png" alt="Mountains" />
            </div>
            <div
              className="parallax-container"
              style={{ transform: `translateX(-${offsetY * 0.25}px)` }}
            >
              {/* <Img fluid={hillImage} alt="Profile Image" /> */}
              <img src="../images/Clouds.png" alt="Clouds" />
            </div>
            <div
              className="parallax-container"
              style={{ transform: `translateX(-${offsetY * 0.2}px)` }}
            >
              {/* <Img fluid={hillImage} alt="Profile Image" /> */}
              <img src="../images/Hills.png" alt="Hills" />
            </div>
            <div
              className="parallax-container"
              style={{ transform: `translateX(-${offsetY * 0.05}px)` }}
            >
              {/* <Img fluid={hillImage} alt="Profile Image" /> */}
              <img src="../images/Trees.png" alt="Trees" />
            </div>
            {/* <div
              className="parallax-container parallax-cover"
              style={{ height: `${offsetY * 0.5}px` }}
            ></div> */}
          </div>
          <div className="content-wrapper">
            <h1>Hi, I'm Le Liu</h1>
            <hr />
            <nav className="me-auto icons">
              <a
                href="https://github.com/leliu1224"
                className="social-button"
                target="_blank"
              >
                <span>
                  <FaGithub />
                </span>
              </a>
              <a
                href="https://twitter.com/leliu0864"
                className="social-button"
                target="_blank"
              >
                <span>
                  <FaTwitter />
                </span>
              </a>
              <a
                href="https://www.linkedin.com/in/leliu864"
                className="social-button"
                target="_blank"
              >
                <span>
                  <FaLinkedin />
                </span>
              </a>
            </nav>
          </div>

          <div className="bottom-content">
            <button className="down-button link-button">
              <Link
                activeClass="active"
                to="about-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <FaChevronDown />
              </Link>
            </button>
          </div>
        </section>

        <section
          id="about-section"
          className="homepage-sections"
          // style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
        >
          <div className="about-section-content-wrapper">
            <div className="tree-image">
              <img src="../images/About_Trees.png" alt="Trees" />
            </div>
            <Fade direction="up" triggerOnce="true">
              <div className="about-section-content">
                <Title>ABOUT ME</Title>

                <div className="about-image">
                  <Img fluid={profileImage} alt="Profile Image" />
                </div>
                <div className="about-description">
                  <p>Hi, I'm Le.</p>
                  <p>
                    I graduated with both a Bachelor in Computer Science and
                    Mechanical Engineering from Rutgers University. While
                    studying for my degree, I discovered the Odin Project (an
                    online web development course) and fell in love with Web
                    Development, a world with endless learning opportunities.
                  </p>
                  <p>
                    I am currently working as a UI Web Developer. I am
                    proficient in HTML, CSS, and Javascript. With experience
                    with libraries such as Bootstrap and React. I also regularly
                    use PhotoShop and Illustrator to create design mockups.
                  </p>
                  <p>
                    In my spare time, I enjoy gaming; currently hopping between
                    Path of Exile and Apex Legends. I also workout frequently
                    and have recently started learning how to play a guitar.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </section>

        <section id="experience-section" className="homepage-sections">
          {/* <div className="border-image">
            <img src="../images/Blue_Border.png" alt="" />
          </div> */}
          <div className="about-section-content-wrapper">
            <div className="experience-section-content">
              <Title>MY EXPERIENCE</Title>
              <div className="experience-content-right">
                <h2>Languages</h2>
                <p>HTML, CSS, JavaScript, C#</p>
                <h2>Libraries / Frameworks</h2>
                <p>Bootstrap, React, Gatsby</p>
                <h2>Softwares</h2>
                <p>Photoshop, Illustrator, GIMP, Inkscape, Figma</p>
              </div>
            </div>
          </div>
        </section>

        <section id="projects-section" className="homepage-sections">
          {/* <div className="border-image">
            <img src="../images/Blue_Border.png" alt="" />
          </div> */}
          <div className="about-section-content-wrapper">
            <div className="experience-section-content">
              <Title>MY PROJECTS</Title>
              <div className="project-card-container">
                <div className="project-card">
                  <div className="card-image">
                    <a href="https://sentosainbrick.com" target="_blank">
                      <Img
                        fluid={projectSentosa}
                        alt="Sentosa Website"
                        className="project-image"
                      />
                    </a>
                  </div>
                  <div className="card-content">
                    <h2>Sentosa Restaurant</h2>
                    <p>
                      A restaurant website I built for a local business.
                      Complete with a home, about, menu, and contact page. Build
                      using React with Gatsby and also BootStrap to help with
                      styling.
                    </p>
                    <small>html / boostrap / react</small>
                    <a
                      href="https://sentosainbrick.com"
                      className="project-link"
                      target="_blank"
                    >
                      Visit Site
                      <span className="project-link-arrow">
                        <BsArrowRightShort />
                      </span>
                    </a>
                  </div>
                </div>
                <hr />
                <div className="project-card ">
                  <div className="card-image">
                    <a
                      href="http://leliu1224.github.io/median-xl-runewords/"
                      target="_blank"
                    >
                      <Img
                        fluid={projectMedianXL}
                        className="project-image"
                        alt="Median XL Runewords Project"
                      />
                    </a>
                  </div>
                  <div className="card-content">
                    <h2 className="">Median XL Runeword Search</h2>
                    <p>
                      A runeword searcher with filter for the popular Median XL
                      mod for Diablo 2. I used Axios to scrap the documentation
                      page and exported the data into a JSON for up-to-date
                      info. The app is built with React and Gatsby.
                    </p>
                    <small>html / css / react</small>
                    <a
                      href="http://leliu1224.github.io/median-xl-runewords/"
                      className="project-link"
                      target="_blank"
                    >
                      Visit Site
                      <span className="project-link-arrow">
                        <BsArrowRightShort />
                      </span>
                    </a>
                  </div>
                </div>
                <hr />
                <div className="project-card">
                  <div className="card-image">
                    <a
                      href="https://pomodoro-app-live.herokuapp.com/"
                      target="_blank"
                    >
                      <Img
                        fluid={projectPomodoro}
                        alt="Pomodoro Project"
                        className="project-image"
                      />
                    </a>
                  </div>
                  <div className="card-content">
                    <h2>Pomodoro Timer</h2>
                    <p>
                      A Pomodoro timer I built to help me be more productive
                      with my time. It has easily adjustable timers allow for
                      quick changes along with a minimalist design.
                    </p>
                    <small>html / css / react</small>
                    <a
                      href="http://todo.leliu.me"
                      className="project-link"
                      target="_blank"
                    >
                      Visit Site
                      <span className="project-link-arrow">
                        <BsArrowRightShort />
                      </span>
                    </a>
                  </div>
                </div>
                <hr />

                <div className="project-card ">
                  <div className="card-image">
                    <a
                      href="https://leliu-pokedex-app-prod.herokuapp.com/"
                      target="_blank"
                    >
                      <Img
                        fluid={projectPokedex}
                        className="project-image"
                        alt="Pokedex App"
                      />
                    </a>
                  </div>
                  <div className="card-content">
                    <h2>Pokedex App</h2>
                    <p>
                      A Pokedex app I built while exploring the PokeAPI. It
                      contains all 151 generation 1 Pokemons along with basic
                      information such as stats, moves and evolutions.
                    </p>
                    <small>html / css / react</small>
                    <a
                      href="https://leliu-pokedex-app-prod.herokuapp.com/"
                      className="project-link"
                      target="_blank"
                    >
                      Visit Site
                      <span className="project-link-arrow">
                        <BsArrowRightShort />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="contact-section"
          className="homepage-sections"
          // style={{ transform: `translateY(-${offsetY * 0.2}px)` }}
        >
          {/* <div className="border-image">
            <img src="../images/Blue_Border.png" alt="" />
          </div> */}
          {/* <Fade direction="down" triggerOnce="true"> */}
          <div className="contact-section-content">
            <Title>CONTACT ME</Title>
            <p>
              If you need to get in touch feel free to send me an{" "}
              <a href="mailto:leliu864@gmail.com">email</a>
            </p>
            <p>Or pass me a message down below.</p>
            {/* <Form
              method="post"
              action="https://getform.io/f/02ec9fe4-9ab3-447b-b0b0-d4074a4f2517"
              className=""
            >
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="name" placeholder="Enter name" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>

              <button variant="primary" type="submit">
                Submit
              </button>
            </Form> */}
            <form
              action="https://getform.io/f/02ec9fe4-9ab3-447b-b0b0-d4074a4f2517"
              method="POST"
            >
              <div className="form-group">
                <label htmlFor="name-input">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name-input"
                  className="form-control"
                  placeholder="Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email-input">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email-input"
                  className="form-control"
                  placeholder="Email"
                />
              </div>

              <div className="form-group">
                <label htmlFor="message-input">Message</label>
                <textarea
                  type="text"
                  name="message"
                  id="message-input"
                  className="form-control"
                  placeholder="Message"
                />
              </div>

              <div className="form-group">
                <button type="submit" className="button">
                  Send
                </button>
              </div>
            </form>
          </div>
          {/* </Fade> */}
        </section>
      </HomeContent>
    </ContentContainer>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    profileImage: file(relativePath: { eq: "profile_image.png" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mountainImage: file(relativePath: { eq: "Mountains.png" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hillImage: file(relativePath: { eq: "Hills.png" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    skyImage: file(relativePath: { eq: "Sky.png" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    projectMedianXL: file(relativePath: { eq: "project_medianxl.png" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    projectPomodoro: file(relativePath: { eq: "project_pomodoro.png" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    projectPokedex: file(relativePath: { eq: "pokedex_app.png" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    projectSentosa: file(relativePath: { eq: "project_sentosa.png" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
